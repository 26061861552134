<template>
  <b-container>
    <b-row>
      <b-col class="d-flex vh-100 flex-column justify-content-center align-items-center">
        <feather type="meh" size="80" style="height: 80px; width: 80px"></feather>
        <h4 class="mt-4">Sorry, the page you are looking for is not available</h4>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  name: 'ErrorAccess',
};
</script>
